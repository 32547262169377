import PropTypes from 'prop-types';
import React, { useState, forwardRef } from 'react';
import cn from 'classnames';
import InputCounter from './inputCounter';
import TextInfoWrapper from './textInfoWrapper';
import Icon from '../icon/icon';
import IconSpinner from '../icon/iconSpinner';
import inputWrapperStyles from './inputWrapper.module.scss';

const InputWrapper = forwardRef(
  (
    {
      className,
      containerClassName,
      placeholder,
      helperText,
      hideHelperText,
      onChange,
      value,
      counterValue,
      status,
      onFocus,
      onBlur,
      name,
      type,
      prefix,
      suffix,
      limit,
      disabled,
      active: activeContainer,
      autoHeight,
      containerless,
      empty,
      loading,
      children,
      onWrapperClick,
      onWrapperBlur,
      onWrapperFocus,
      mnT,
      mnR,
      mnB,
      mnL,
      ...props
    },
    ref
  ) => {
    const [active, setActive] = useState(false);

    if (status) {
      if (status === 'success')
        suffix = (
          <Icon
            lg
            type="icon-status-check"
            className={cn(inputWrapperStyles.statusIcon, inputWrapperStyles.statusSuccess)}
          />
        );
      else if (status === 'alert')
        suffix = (
          <Icon
            lg
            type="icon-status-warning"
            className={cn(inputWrapperStyles.statusIcon, inputWrapperStyles.statusAlert)}
          />
        );
      else if (status === 'error')
        suffix = (
          <Icon
            lg
            type="icon-status-error"
            className={cn(inputWrapperStyles.statusIcon, inputWrapperStyles.statusError)}
          />
        );
    }

    if (loading) {
      suffix = <IconSpinner />;
    }

    const commonProps = {
      autoComplete: 'chrome-off',
      contentEditable: false,
      disabled,
      value,
      name,
      type,
      onFocus: e => {
        setActive(true);
        onFocus(e);
      },
      onBlur: e => {
        setActive(false);
        onBlur(e);
      },
      onChange: e => {
        if (limit !== 0 && e.target.value.length >= limit + 1) return;
        onChange(e);
      },
      placeholder,
      containerless,
      ...props,
    };

    const childElement = typeof children === 'function' ? children({ inputProps: commonProps }) : children;

    return (
      <div
        ref={ref}
        className={cn(inputWrapperStyles.root, className, {
          [inputWrapperStyles.active]: active || activeContainer,
          [inputWrapperStyles.autoHeight]: autoHeight,
          [inputWrapperStyles.withData]: Array.isArray(value) ? !!value.length : !!value,
          [inputWrapperStyles.empty]: empty,
          [inputWrapperStyles.containerless]: containerless,
          [inputWrapperStyles.disabled]: disabled,
          [inputWrapperStyles.hideHelperText]: hideHelperText,
          [inputWrapperStyles.error]: status === 'error',
          [inputWrapperStyles.success]: status === 'success',
          [inputWrapperStyles.alert]: status === 'alert',
          [inputWrapperStyles.marginTop]: mnT,
          [inputWrapperStyles.marginRight]: mnR,
          [inputWrapperStyles.marginBottom]: mnB,
          [inputWrapperStyles.marginLeft]: mnL,
        })}
        onFocus={onWrapperFocus}
        onClick={onWrapperClick}
        onBlur={onWrapperBlur}
      >
        <TextInfoWrapper
          helperText={helperText}
          hideHelperText={hideHelperText}
          status={status}
          right={
            !!limit && (
              <InputCounter
                limit={limit}
                value={counterValue !== undefined ? counterValue : value}
                className={inputWrapperStyles.limit}
              />
            )
          }
        >
          <div className={cn(inputWrapperStyles.container, containerClassName)}>
            <div className={inputWrapperStyles.main}>
              {prefix && <div className={inputWrapperStyles.prefix}>{prefix}</div>}
              {
                // eslint-disable-next-line
                React.cloneElement(childElement)
              }
            </div>
            {suffix && <div className={inputWrapperStyles.suffix}>{suffix}</div>}
          </div>
        </TextInfoWrapper>
      </div>
    );
  }
);

InputWrapper.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  hideHelperText: PropTypes.bool,
  limit: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  containerless: PropTypes.bool,
  autoHeight: PropTypes.bool,
  active: PropTypes.bool,
  prefix: PropTypes.object,
  suffix: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  counterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  status: PropTypes.oneOf(['alert', 'success', 'error']),
  empty: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onWrapperClick: PropTypes.func,
  onWrapperBlur: PropTypes.func,
  onWrapperFocus: PropTypes.func,
  mnT: PropTypes.bool,
  mnR: PropTypes.bool,
  mnB: PropTypes.bool,
  mnL: PropTypes.bool,
};

InputWrapper.defaultProps = {
  className: undefined,
  containerClassName: undefined,
  placeholder: undefined,
  helperText: undefined,
  hideHelperText: false,
  limit: 0,
  name: undefined,
  type: 'text',
  disabled: false,
  containerless: false,
  active: false,
  autoHeight: false,
  prefix: undefined,
  suffix: undefined,
  value: '',
  counterValue: undefined,
  status: undefined,
  empty: undefined,
  loading: false,
  children: undefined,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onWrapperClick: () => {},
  onWrapperBlur: () => {},
  onWrapperFocus: () => {},
  mnT: false,
  mnR: false,
  mnB: false,
  mnL: false,
};

export default React.memo(InputWrapper);
