import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import tabsStyles from './tabs.module.scss';

const Tabs = ({ source, onChange, value }) => {
  return (
    <div className={tabsStyles.root}>
      <div className={tabsStyles.content}>
        {source.map(item => {
          return (
            <div
              key={item.value}
              className={cn(tabsStyles.item, {
                [tabsStyles.selected]: item.value === value,
              })}
              onClick={() => {
                onChange(item);
              }}
            >
              {item.text}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tabs;

Tabs.propTypes = {
  onChange: PropTypes.func,
  source: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
};

Tabs.defaultProps = {
  onChange: () => {},
};
