import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import notFoundStyles from './notFound.module.scss';

const NotFound = ({ className }) => {
  return (
    <div className={cn(notFoundStyles.root, className)}>
      <div className={notFoundStyles.title}>Not Found</div>
    </div>
  );
};

NotFound.propTypes = {
  className: PropTypes.string,
};

NotFound.defaultProps = {
  className: '',
};

export default NotFound;
