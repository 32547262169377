import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import cn from 'classnames';
import textStyles from './textInfoWrapper.module.scss';

const TextInfoWrapper = forwardRef(
  ({ className, helperText, hideHelperText, status, children, right, mnT, mnR, mnB, mnL }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(textStyles.root, className, {
          [textStyles.hideHelperText]: hideHelperText,
          [textStyles.error]: status === 'error',
          [textStyles.success]: status === 'success',
          [textStyles.alert]: status === 'alert',
          [textStyles.marginTop]: mnT,
          [textStyles.marginRight]: mnR,
          [textStyles.marginBottom]: mnB,
          [textStyles.marginLeft]: mnL,
        })}
      >
        <div>{children}</div>
        {(helperText || right) && (
          <div className={cn(textStyles.help, { [textStyles.helpEnd]: !helperText })}>
            {helperText && <div className={textStyles.helperText}>{helperText}</div>}
            {right}
          </div>
        )}
      </div>
    );
  }
);

TextInfoWrapper.propTypes = {
  className: PropTypes.string,
  helperText: PropTypes.string,
  hideHelperText: PropTypes.bool,
  status: PropTypes.oneOf(['alert', 'success', 'error']),
  children: PropTypes.object,
  mnT: PropTypes.bool,
  mnR: PropTypes.bool,
  mnB: PropTypes.bool,
  mnL: PropTypes.bool,
  right: PropTypes.any,
};

TextInfoWrapper.defaultProps = {
  className: undefined,
  helperText: undefined,
  hideHelperText: false,
  status: undefined,
  children: undefined,
  mnT: false,
  mnR: false,
  mnB: false,
  mnL: false,
  right: undefined,
};

export default React.memo(TextInfoWrapper);
