import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import loaderStyles from './loader.module.scss';

const Loader = ({ className }) => {
  return (
    <div className={cn(loaderStyles.root, className)}>
      <div className={loaderStyles.title}>Loading...</div>
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
};

Loader.defaultProps = {
  className: '',
};

export default Loader;
