import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import accordionStyles from './accordion.module.scss';
import Icon from '../icon/icon';

const Accordion = ({ source, onClick }) => {
  return (
    <div className={accordionStyles.root}>
      {source.map(item => (
        <div
          className={cn(accordionStyles.item, {
            [accordionStyles.expanded]: item.expanded,
          })}
          key={item.value}
          onClick={() => {
            if (!item.expanded) {
              onClick(item);
            }
          }}
        >
          <div
            className={accordionStyles.header}
            onClick={() => {
              if (item.expanded) {
                onClick(item);
              }
            }}
          >
            <div className={accordionStyles.title}>{item.title}</div>
            <Icon className={accordionStyles.icon} type="icon-chevron" />
          </div>
          <div className={accordionStyles.text}>{item.text}</div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;

Accordion.propTypes = {
  onClick: PropTypes.func,
  source: PropTypes.array,
};

Accordion.defaultProps = {
  onClick: () => {},
  source: [],
};
