import React from 'react';
import iconSpinnerStyles from './iconSpinner.module.scss';

const IconSpinner = () => {
  return (
    <div className={iconSpinnerStyles.root}>
      <div className={iconSpinnerStyles.container}>
        {[...Array(8).keys()].map(v => (
          <div key={v} />
        ))}
      </div>
    </div>
  );
};

export default IconSpinner;
