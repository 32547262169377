import React, { useEffect, useRef } from 'react';
import { buildCDNImageUrl } from '../../utils/imageUtils';

const HeartAnimation = () => {
  const image1 = buildCDNImageUrl('/common/hearts/h_1_cursor.svg');
  const image2 = buildCDNImageUrl('/common/hearts/h_2_cursor.svg');
  const image3 = buildCDNImageUrl('/common/hearts/h_3_cursor.svg');
  const image4 = buildCDNImageUrl('/common/hearts/h_4_cursor.svg');
  const image5 = buildCDNImageUrl('/common/hearts/h_5_cursor.svg');
  const image6 = buildCDNImageUrl('/common/hearts/h_6_cursor.svg');

  const requestAnimationFrameRef = useRef();

  useEffect(() => {
    let particles = [];
    const sizes = [8, 12, 16, 24];
    const limit = 35;
    const variants = [image1, image1, image1, image2, image3, image4, image5, image6];
    let mouseX;
    let mouseY;
    let autoAddParticle;

    // eslint-disable-next-line
    const touchEvents = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    const down = touchEvents ? 'touchstart' : 'mousedown';
    const move = touchEvents ? 'touchmove' : 'mousemove';
    const up = touchEvents ? 'touchend' : 'mouseup';

    requestAnimationFrameRef.current = true;

    const createParticle = () => {
      const path = variants[Math.floor(Math.random() * variants.length)];
      const e = sizes[Math.floor(Math.random() * sizes.length)];
      const t = 10 * Math.random();
      const n = 25 * Math.random();
      const r = 360 * Math.random();
      const a = 35 * Math.random() * (Math.random() <= 0.5 ? -1 : 1);
      const i = mouseY - e / 2;
      const o = mouseX - e / 2;
      const s = Math.random() <= 0.5 ? -1 : 1;
      // eslint-disable-next-line
      const l = document.createElement('img');
      l.classList.add('particle');
      l.setAttribute('src', path);
      // Left this line if we decide to rotate hearts
      // l.setAttribute('style', `width: ${e}px; height: ${e}px; top: ${i}px; left: ${o}px; transform: rotate(${r}deg)`),
      l.setAttribute('style', `width: ${e}px; height: ${e}px; top: ${i}px; left: ${o}px;`);

      // eslint-disable-next-line
      document.getElementById('particles').appendChild(l);
      particles.push({
        direction: s,
        element: l,
        left: o,
        size: e,
        speedHorz: t,
        speedUp: n,
        spinSpeed: a,
        spinVal: r,
        top: i,
      });
    };

    const updateParticles = () => {
      particles.forEach(t => {
        t.left -= t.speedHorz * t.direction;
        t.top -= t.speedUp;
        t.speedUp = Math.min(t.size, t.speedUp - 1);
        t.spinVal += t.spinSpeed;
        // eslint-disable-next-line
        if (t.top >= Math.max(window.innerHeight, document.body.clientHeight) + t.size) {
          particles = particles.filter(e => e !== t);
          t.element.remove();
        }

        t.element.setAttribute(
          'style',
          // Left this line if we decide to rotate hearts
          // `top: ${t.top}px; left: ${t.left}px; width: ${t.size}px; height: ${t.size}px; transform: rotate(${t.spinVal}deg)`
          `top: ${t.top}px; left: ${t.left}px; width: ${t.size}px; height: ${t.size}px;`
        );
      });
    };

    const onMouseDownHandler = t => {
      let n;
      let r;
      /* eslint-disable */
      mouseX = t.pageX || (null === (n = t.touches[0]) || void 0 === n ? void 0 : n.pageX);
      mouseY = t.pageY || (null === (r = t.touches[0]) || void 0 === r ? void 0 : r.pageY);
      /* eslint-enable */
      autoAddParticle = !0;
    };

    const loop = () => {
      if (requestAnimationFrameRef.current) {
        if (autoAddParticle && particles.length < limit) {
          createParticle();
        }
        updateParticles();
        // eslint-disable-next-line
        requestAnimationFrame(loop);
      }
    };

    const onMouseUpHandler = () => {
      autoAddParticle = !1;
    };

    const onMouseLeaveHandler = () => {
      autoAddParticle = !1;
    };

    const onMouseMoveHandler = t => {
      let n;
      let r;
      /* eslint-disable */
      mouseX = t.pageX || (null === (n = t.touches) || void 0 === n ? void 0 : n[0].pageX);
      mouseY = t.pageY || (null === (r = t.touches) || void 0 === r ? void 0 : r[0].pageY);
      /* eslint-enable */
    };

    /* eslint-disable */
    window.addEventListener(down, onMouseDownHandler);
    window.addEventListener('mouseleave', onMouseLeaveHandler, { passive: !1 });
    window.addEventListener(move, onMouseMoveHandler);
    window.addEventListener(up, onMouseUpHandler);
    /* eslint-enable */

    loop();

    return () => {
      /* eslint-disable */
      window.removeEventListener(down, onMouseDownHandler);
      window.removeEventListener('mouseleave', onMouseLeaveHandler);
      window.removeEventListener(move, onMouseMoveHandler);
      window.removeEventListener(up, onMouseUpHandler);
      /* eslint-enable */
      requestAnimationFrameRef.current = false;
    };
  }, []);

  return <div />;
};

export default HeartAnimation;
