import PropTypes from 'prop-types';
import React from 'react';
import InputWrapper from './inputWrapper';

const TextArea = ({ rows, limit, ...props }) => {
  return (
    <InputWrapper {...props} limit={limit} autoHeight>
      {({ inputProps }) => (
        <textarea
          onKeyDown={e => {
            if (e.key.toLowerCase() === 'enter' || e.keyCode === 13) {
              e.stopPropagation();
            }
          }}
          {...inputProps}
          rows={rows}
        />
      )}
    </InputWrapper>
  );
};

TextArea.propTypes = {
  rows: PropTypes.number,
  limit: PropTypes.number,
};

TextArea.defaultProps = {
  rows: undefined,
  limit: 500,
};

export default TextArea;
