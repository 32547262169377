import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import inputCounterStyles from './inputCounter.module.scss';

const InputCounter = ({ limit, value, className }) => {
  const intl = useIntl();

  if (limit === 0) return null;

  return (
    <span className={cn(inputCounterStyles.root, className)}>
      {intl.formatMessage({ id: 'common.input.limit' }, { limit, current: value ? value.length : 0 })}
    </span>
  );
};

InputCounter.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  limit: PropTypes.number,
};

InputCounter.defaultProps = {
  className: undefined,
  value: undefined,
  limit: 0,
};

export default InputCounter;
